.required-field-indicator {
	@apply inline-block relative w-fit;

	&::after {
		content: '*' !important;
		@apply absolute top-0 -right-2 text-red-500;
	}
}

.label {
	@apply font-semibold text-sm text-gray-600;
}

.form-control {
	label {
		@apply font-semibold text-sm text-gray-600;
	}

	.form-message {
		@apply text-sm text-red-500;
	}

	textarea,
	.input-custom {
		@apply w-full rounded-lg px-4 py-3 border-tremor-border focus:ring-tremor-brand-muted text-gray-500 outline-0 focus:ring-2 placeholder:text-sm placeholder:text-gray-500 hover:bg-gray-50 focus:outline-0 focus-visible:outline-none;
	}
}

.with-scrollbar {
	/* Scroll Bar */
	&::-webkit-scrollbar {
		@apply my-6 w-1;

		/* Track */
		&-track {
			@apply bg-transparent;
		}

		/* Handle */
		&-thumb {
			@apply m-1 my-10 rounded-lg bg-[#d2dbe5] bg-opacity-50 transition duration-300;
		}

		/* Handle on hover */
		&-thumb:hover {
			@apply bg-gray-500 bg-opacity-80;
		}
	}

	scrollbar-width: thin;
	scrollbar-color: #b1b6bec7 #edf2f941;

	&-light {
		/* Scroll Bar */
		&::-webkit-scrollbar {
			@apply my-6 w-2;

			/* Track */
			&-track {
				@apply bg-transparent;
			}

			/* Handle */
			&-thumb {
				@apply m-1 my-10 rounded-lg bg-gray-700 bg-opacity-30 transition duration-300;
			}

			/* Handle on hover */
			&-thumb:hover {
				@apply bg-primary-700 bg-opacity-40;
			}
		}

		scrollbar-width: thin;
		scrollbar-color: #b1b6bec7 #edf2f941;
	}
}
