:root {
	--sidebar-width: 250px;
	--appbar-height-mobile: 70px;
	--appbar-height-desktop: 80px;
	--app-navigation-height: 40px;
	--app-branding-width-lg: 280px;
	--prospect-detail-page-height: 205px;
	--create-prospect-page-height: 225px;
	--prospect-detail-page-height-information-page: 205px;
}

@media (max-height: 900px) {
	:root {
		--prospect-detail-page-height: 105px;
		--prospect-detail-page-height-information-page: 155px;
	}
}

button,
a {
	-webkit-tap-highlight-color: transparent !important; /* for removing the highlight */
}

input,
select,
textarea {
	/*Input Autofill Background Change*/
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active,
	&:-moz-autofill,
	&:-moz-autofill:hover,
	&:-moz-autofill:focus,
	&:-moz-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: #333 !important;
		-webkit-box-shadow: 0 0 0 30px theme('colors.gray.700') inset !important;
	}
}

// Tremor JS
.border-tremor-border {
	@apply border-gray-200;
}
