@tailwind base;
@tailwind components;
@tailwind utilities;

@import './partials/resets';
@import './partials/utils';
@import './partials/variables';

@media (max-width: 639px) {
	.custom-table {
		thead,
		th {
			display: none;
		}

		td {
			display: grid;
			gap: 0.5rem;
			grid-template-columns: 15ch auto;
			padding: 0.5rem 1rem;
		}

		td:first-child {
			padding-top: 2rem;
			display: block;

			&::before {
				content: '' !important;
			}
		}

		td:last-child {
			padding-bottom: 2rem;
		}

		td::before {
			content: attr(data-cell) ': ';
			font-weight: 700;
			text-transform: capitalize;
		}
	}
}
